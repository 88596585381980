import React, { useEffect, useMemo, useState } from "react";
import axios from "../../utils/axios";
import {
  Container,
  Stack,
  CircularProgress,
  TablePagination,
  Box,
  TableBody,
  Table,
  Divider,
  TableContainer,
  Card,
  Collapse,
  Typography,
  TableCell,
  TableRow,
  Chip,
  Button,
  TextField,
  Autocomplete,
  Slider,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import usePlayersIManage from "src/hooks/usePlayersIManage";
import useTable from "src/hooks/useTable";
import { TableHeadCustom, TableNoData } from "src/components/table";
import Scrollbar from "src/components/Scrollbar";
import InvoiceAnalytic from "src/sections/invoice/InvoiceAnalytic";
import SearchToolbar from "src/sections/@dashboard/player/manage/SearchToolbar";
import axiosInstance from "src/utils/axios";
import Avatar from "src/components/Avatar";
import { useSnackbar } from "notistack";
import {
  RHFCheckbox,
  RHFTextField,
  FormProvider,
  RHFSelect,
} from "src/components/hook-form";
import { useForm, Controller } from "react-hook-form";
import {
  fetchgamerTags,
  fetchGames,
  playerTypes,
} from "src/sections/@dashboard/user/account/PlayerAccountGeneral";
import LocationCard from "src/sections/@dashboard/user/account/OrganizationAccountGeneral/Location";
import useAuth from "src/hooks/useAuth";
import requestHeaders from "src/utils/restClient";
import { useLocation } from "react-router";
import Loader from "./Loader";

const TABLE_HEAD = [
  { id: "avatar", label: "Avatar", align: "left" },
  { id: "name", label: "Name", align: "left" },
  { id: "type", label: "Entity Type", align: "center" },
];

const LOL_HEAD = [
  { id: "gamerId", label: "Gamer ID", align: "left" },
  { id: "leaguePoints", label: "League Points", align: "center" },
  { id: "kda", label: "KDA", align: "center" },
];

export default function Search() {
  const { user } = useAuth();
  const { themeStretch } = useSettings();
  const [results, setResults] = useState([]);
  const { players, isLoading } = usePlayersIManage();
  const [searchInitiated, setSearchInitiated] = useState(false); // New state to track search initiation
  const { enqueueSnackbar } = useSnackbar();
  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({});

  const [tableData, setTableData] = useState(players);
  const [filterName, setFilterName] = useState("");

  const [openCard, setOpenCard] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedGame, setSelectedGame] = useState("League of Legends");

  useEffect(() => {
    setTableData(
      players.map((player) => {
        player.username = player.userId.username;
        player.email = player.userId.email;
        return player;
      })
    );
  }, [players]);

  // Function to reset the form
  const handleReset = () => {
    setSelectedGame("");
    reset({ ...defaultValues, gamesPlayedTeam: [] });
    // setResults([]); // Clear previous search results
  };

  const handleSearchFilter = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  // Clear the search field when switching between tabs
  useEffect(() => {
    setFilterName(""); // Clear the search field
    setPage(0); // Reset to the first page
    // eslint-disable-next-line
  }, [openCard]); // Runs when the tab changes

  // Function to toggle the visibility of cards
  const handleToggleCard = (card) => {
    setOpenCard((prev) => (prev === card ? null : card));
  };

  const location = useLocation();

  // Parse query params from the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameParam = searchParams.get("name"); // Get the "name" query param

    if (nameParam) {
      setFilterName(nameParam); // Set filterName state to the parsed value
      //handleSearch(nameParam); // Optionally trigger a search with the param
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameParam = searchParams.get("name"); // Get the "name" query param

    if (nameParam && filterName) {
      handleSearch(); // Optionally trigger a search with the param
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  const handleSearch = async () => {
    if (!filterName) {
      enqueueSnackbar("Search query cannot be empty.", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    // Set search initiated to true when the search button is pressed
    setIsSearching(true);
    setSearchInitiated(true);
    setOpenCard(null);
    // Search logic goes here
    const data = await axiosInstance.post("/search", {
      searchTerm: filterName,
    });
    setResults(data.data);
    setIsSearching(false);
  };

  // Search users form
  const defaultValues = {
    // Players
    gamerID: "",
    // Stats
    game: "",
    kda: "",
    kills: "",
    deaths: "",
    assists: "",
    cs: "",
    leaguePoints: "",
    // games: [],
    gamerTags: [],
    yearRange: [2018, 2030],
    gpaRange: [0, 6],
    textBasedPlayerSearch: "",
    // Users
    username: "",
    isLookingForCollegeTeam: false,
    // Teams
    teamName: "",
    gamesPlayedTeam: [],
    isCollegeTeam: false,
    scholarshipsOffered: false,
    textBasedSearchTeams: "",
    // Orgs
    textBasedSearchOrganizations: "",
    organizationName: "",
    locationCity: "",
    locationState: "",
    locationCountryCode: "",
    locationZipCode: "",
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCard, reset]);

  // Submit function for Players Filters
  const handleSubmitPlayersFilters = async (data) => {
    // console.log({
    //   gamerID: data.gamerID,
    //   playerType: data.type,
    //   games: data.games,
    //   gamerTags: data.gamerTags,
    //   textBasedPlayerSearch: data.textBasedPlayerSearch,
    //   collegeType: data.collegeType,
    //   desiredDegrees: data.desiredDegrees,
    //   desiredSchools: data.desiredSchools,
    //   desiredRegions: data.desiredRegions,
    //   country: data.locationCountryCode,
    //   city: data.locationCity,
    //   state: data.locationState,
    //   zip: data.locationZipCode,
    //   graduationYear: data.yearRange,
    //   gpaRange: data.gpaRange,
    // });
    try {
      setFilterName(""); // Clear the search field
      setIsSearching(true);
      setSearchInitiated(true);
      const response = await axiosInstance.post("/search/players", {
        gamerID: data.gamerID,
        playerType: data.type,
        games: data.games,
        gamerTags: data.gamerTags,
        textBasedPlayerSearch: data.textBasedPlayerSearch,
        collegeType: data.collegeType,
        desiredDegrees: data.desiredDegrees,
        desiredSchools: data.desiredSchools,
        desiredRegions: data.desiredRegions,
        country: data.locationCountryCode,
        city: data.locationCity,
        state: data.locationState,
        zip: data.locationZipCode,
        graduationYear: data.yearRange,
        gpaRange: data.gpaRange,
      });
      setResults(response.data);
      setIsSearching(false);
      enqueueSnackbar("Players search completed.", { variant: "success" });
    } catch (error) {
      console.log(error);
      setIsSearching(false);
      enqueueSnackbar("Failed to search players.", { variant: "error" });
    }
  };

  // Submit function for Users Filters
  const handleSubmitUsersFilters = async (data) => {
    try {
      setFilterName(""); // Clear the search field
      setIsSearching(true);
      setSearchInitiated(true);
      // console.log({
      //   username: data.username,
      //   isLookingForCollegeTeam: data.isLookingForCollegeTeam,
      // });
      const response = await axiosInstance.post("/search/users", {
        username: data.username,
        isLookingForCollegeTeam: data.isLookingForCollegeTeam,
      });
      setResults(response.data);
      setIsSearching(false);
      enqueueSnackbar("Users search completed.", { variant: "success" });
    } catch (error) {
      setIsSearching(false);
      enqueueSnackbar("Failed to search users.", { variant: "error" });
    }
  };

  // Submit function for Teams Filters
  const handleSubmitTeamsFilters = async (data) => {
    try {
      setFilterName(""); // Clear the search field
      // console.log({
      //   teamName: data.teamName,
      //   gamesPlayed: data.gamesPlayedTeam,
      //   country: data.locationCountryCode,
      //   city: data.locationCity,
      //   state: data.locationState,
      //   zip: data.locationZipCode,
      //   isCollegeTeam: data.isCollegeTeam,
      //   textBasedSearchTeams: data.textBasedSearchTeams,
      // });
      setIsSearching(true);
      setSearchInitiated(true);
      const response = await axiosInstance.post("/search/teams", {
        teamName: data.teamName,
        gamesPlayed: data.gamesPlayedTeam,
        country: data.locationCountryCode,
        city: data.locationCity,
        state: data.locationState,
        zip: data.locationZipCode,
        isCollegeTeam: data.isCollegeTeam,
        textBasedSearchTeams: data.textBasedSearchTeams,
      });
      setResults(response.data);
      setIsSearching(false);
      enqueueSnackbar("Teams search completed.", { variant: "success" });
    } catch (error) {
      setIsSearching(false);
      enqueueSnackbar("Failed to search teams.", { variant: "error" });
    }
  };

  // Submit function for Organizations Filters
  const handleSubmitOrganizationsFilters = async (data) => {
    try {
      setFilterName(""); // Clear the search field
      // console.log({
      //   organizationName: data.organizationName,
      //   degreesOffered: data.degreesOffered,
      //   scholarshipsOffered: data.scholarshipsOffered,
      //   country: data.locationCountryCode,
      //   city: data.locationCity,
      //   state: data.locationState,
      //   zip: data.locationZipCode,
      //   textBasedSearchOrganizations: data.textBasedSearchOrganizations,
      // });
      setIsSearching(true);
      setSearchInitiated(true);
      const response = await axiosInstance.post("/search/organizations", {
        organizationName: data.organizationName,
        degreesOffered: data.degreesOffered,
        scholarshipsOffered: data.scholarshipsOffered,
        country: data.locationCountryCode,
        city: data.locationCity,
        state: data.locationState,
        zip: data.locationZipCode,
        textBasedSearchOrganizations: data.textBasedSearchOrganizations,
      });
      setResults(response.data);
      setIsSearching(false);
      enqueueSnackbar("Organizations search completed.", {
        variant: "success",
      });
    } catch (error) {
      setIsSearching(false);
      enqueueSnackbar("Failed to search organizations.", { variant: "error" });
    }
  };

  // Submit function for Stats Filters
  const handleSubmitStatsFilters = async (data) => {
    try {
      setFilterName("");
      setIsSearching(true);
      setSearchInitiated(true);
      const response = await axiosInstance.post("/search/stats", {
        game: data.game,
        kda: data.kda,
        kills: data.kills,
        deaths: data.deaths,
        assists: data.assists,
        cs: data.cs,
        leaguePoints: data.leaguePoints,
        gamerID: data.gamerID,
        playerType: data.type,
        gamerTags: data.gamerTags,
      });
      setResults(response.data);
      setIsSearching(false);
      enqueueSnackbar("Game stats search completed.", { variant: "success" });
    } catch (error) {
      console.log(error);
      setIsSearching(false);
      enqueueSnackbar("Failed to search stats.", { variant: "error" });
    }
  };

  // Search players form
  const [gamerTags, setGamerTags] = useState([]);
  const [games, setGames] = useState([]);

  const appHeaders = requestHeaders(localStorage.getItem("accessToken"));
  const [desiredDegrees, setDesiredDegrees] = useState([]);
  const [desiredSchools, setDesiredSchools] = useState([]);
  const [desiredRegions, setDesiredRegions] = useState([]);
  const [collegeType, setCollegeType] = useState([]);
  const [collegeOptionsFetched, setCollegeOptionsFetched] = useState(false);
  const [dbTotalCounts, setDbTotalCounts] = useState(null);
  const [dbTotalCountsFetched, setDbTotalCountsFetched] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      const endpoints = [
        "/colleges/types",
        "/colleges/degrees",
        "/colleges/regions",
        "/colleges/options",
      ];
      setCollegeOptionsFetched(true);
      const [types, degrees, regions, options] = await Promise.all(
        endpoints.map(async (endpoint) =>
          axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            headers: appHeaders,
          })
        )
      );

      setCollegeType(types.data);
      setDesiredDegrees(degrees.data);
      setDesiredRegions(regions.data);
      setDesiredSchools(options.data);
    };
    if (!collegeOptionsFetched) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaders]);

  useEffect(() => {
    const fetchOptions = async () => {
      const endpoints = ["/search-total-counts"];
      setDbTotalCountsFetched(true);
      const [totalCounts] = await Promise.all(
        endpoints.map(async (endpoint) =>
          axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            headers: appHeaders,
          })
        )
      );
      setDbTotalCounts(totalCounts.data);
    };
    if (!dbTotalCounts && !dbTotalCountsFetched) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaders]);

  useEffect(() => {
    fetchgamerTags(setGamerTags);
    fetchGames(setGames);
  }, []);

  const filteredResult = useMemo(() => {
    return results
      .map((result) => ({
        type: result.model,
        name: result.username || result.name,
        avatar: result.avatarUrl,
        leaguePoints: result.leaguePoints ? result.leaguePoints : undefined,
        averages: result.averages ? result.averages : undefined,
        gamerId: result.gamerId ? result.gamerId : undefined,
        _id: result._id,
      }))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [results, rowsPerPage, page]);

  return (
    <Page title="Search">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading="Search" links={[]} />
        <SearchToolbar
          filterName={filterName}
          onFilterName={handleSearchFilter}
          handleSearch={handleSearch}
        />

        <Typography sx={{ mb: 3 }}>
          You can also refine your search by exploring specific categories such
          as Players, Users, Teams, or Organizations to find exactly what you're
          looking for.
        </Typography>
        <Loader loading={dbTotalCounts === null}>
          {dbTotalCounts && (
            <Card sx={{ mb: 5 }}>
              <Scrollbar>
                <Stack
                  direction="row"
                  divider={
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ borderStyle: "dashed" }}
                    />
                  }
                  sx={{ py: 2 }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor:
                        openCard === "players"
                          ? "rgba(90, 175, 226, 0.1)"
                          : "inherit", // Apply hover color when selected
                      "&:hover": {
                        backgroundColor: "rgba(90, 175, 226, 0.1)", // Light blue background on hover
                        transform: "scale(1.02)", // Slight zoom effect on hover
                        transition: "transform 0.2s, background-color 0.2s",
                      },
                    }}
                    onClick={() => handleToggleCard("players")}
                  >
                    {dbTotalCounts && (
                      <InvoiceAnalytic
                        title="Players"
                        total={dbTotalCounts.playersCount + " players"}
                        percent={100}
                        price={10}
                        icon="ion:game-controller"
                        color="#5AAFE2"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor:
                        openCard === "users"
                          ? "rgba(164, 222, 114, 0.1)"
                          : "inherit", // Apply hover color when selected
                      "&:hover": {
                        backgroundColor: "rgba(164, 222, 114, 0.1)", // Light green background on hover
                        transform: "scale(1.02)", // Slight zoom effect on hover
                        transition: "transform 0.2s, background-color 0.2s",
                      },
                    }}
                    onClick={() => handleToggleCard("users")}
                  >
                    {dbTotalCounts && (
                      <InvoiceAnalytic
                        title="Users"
                        total={dbTotalCounts.usersCount + " users"}
                        percent={10}
                        price={10}
                        icon="fa-solid:user"
                        color="#A4DE72"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor:
                        openCard === "teams"
                          ? "rgba(239, 218, 95, 0.1)"
                          : "inherit", // Apply hover color when selected
                      "&:hover": {
                        backgroundColor: "rgba(239, 218, 95, 0.1)", // Light yellow background on hover
                        transform: "scale(1.02)", // Slight zoom effect on hover
                        transition: "transform 0.2s, background-color 0.2s",
                      },
                    }}
                    onClick={() => handleToggleCard("teams")}
                  >
                    {dbTotalCounts && (
                      <InvoiceAnalytic
                        title="Teams"
                        total={dbTotalCounts.teamsCount + " teams"}
                        percent={10}
                        price={10}
                        icon="fa6-solid:user-group"
                        color="#EFDA5F"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor:
                        openCard === "organizations"
                          ? "rgba(241, 148, 117, 0.1)"
                          : "inherit", // Apply hover color when selected
                      "&:hover": {
                        backgroundColor: "rgba(241, 148, 117, 0.1)", // Light orange background on hover
                        transform: "scale(1.02)", // Slight zoom effect on hover
                        transition: "transform 0.2s, background-color 0.2s",
                      },
                    }}
                    onClick={() => handleToggleCard("organizations")}
                  >
                    {dbTotalCounts && (
                      <InvoiceAnalytic
                        title="Organizations"
                        total={
                          dbTotalCounts.organizationsCount + " organizations"
                        }
                        percent={10}
                        price={10}
                        icon="fa-regular:building"
                        color="#F19475"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor:
                        openCard === "stats"
                          ? "rgba(90, 175, 226, 0.1)"
                          : "inherit", // Apply hover color when selected
                      "&:hover": {
                        backgroundColor: "rgba(90, 175, 226, 0.1)", // Light blue background on hover
                        transform: "scale(1.02)", // Slight zoom effect on hover
                        transition: "transform 0.2s, background-color 0.2s",
                      },
                    }}
                    onClick={() => handleToggleCard("stats")}
                  >
                    {dbTotalCounts && (
                      <InvoiceAnalytic
                        title="Game Stats"
                        total={dbTotalCounts.statsCount + " stats"}
                        percent={100}
                        price={10}
                        icon="ion:stats-chart"
                        color="#EC3BCE"
                      />
                    )}
                  </Box>
                </Stack>

                {/* Render the additional cards based on openCards state */}
                <Collapse in={openCard === "players"}>
                  <Card
                    sx={{
                      mt: 2,
                      p: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Stack direction="column" py={2} px={1} spacing={2}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                      >
                        Player Filters
                      </Typography>
                      <FormProvider
                        methods={methods} // You can reuse the same `methods` if both forms use the same validation schema
                        onSubmit={handleSubmit(handleSubmitPlayersFilters)}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 3,
                          }}
                        >
                          <RHFTextField name="gamerID" label="Gamer ID" />
                          <Controller
                            name={`type`}
                            defaultValue={[]}
                            control={control}
                            render={({
                              field: gamerTypeField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="player-type-tags"
                                options={playerTypes}
                                {...gamerTypeField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamerTypeField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Player Type"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                          <Controller
                            name={`games`}
                            defaultValue={[]}
                            control={control}
                            render={({
                              field: gamesField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-games"
                                options={games.map(({ name }) => name)}
                                {...gamesField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamesField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Games"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                          <Controller
                            defaultValue={[]}
                            sx={{ mt: 2, maxWidth: { md: "50%" } }}
                            name={`gamerTags`}
                            control={control}
                            render={({
                              field: gamerTagsField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={gamerTags.map(({ name }) => name)}
                                {...gamerTagsField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamerTagsField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Gamer Tags"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                        <LocationCard
                          city={values.locationCity}
                          state={values.locationState}
                          country={values.locationCountryCode}
                        />
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                        >
                          Text-Based Player Search
                        </Typography>
                        <RHFTextField
                          name="textBasedPlayerSearch"
                          label="Text-based search"
                          multiline
                          rows={4}
                          helperText={
                            user.goals.isCollegeTeamRecruitingPlayers &&
                              user.isVerifiedCollegeRep ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  mt: 1,
                                  ml: -1,
                                  //mx: 'auto',
                                  display: "block",
                                  textAlign: "left",
                                  color: "text.secondary",
                                }}
                              >
                                Enter keywords to search across player bios and
                                college statements. This will help you find
                                players whose profiles and aspirations match
                                your criteria.
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  mt: 1,
                                  ml: -1,
                                  //mx: 'auto',
                                  display: "block",
                                  textAlign: "left",
                                  color: "text.secondary",
                                }}
                              >
                                Enter any keywords to search within player bios.
                                This search will match your input with relevant
                                text in player profiles, helping you find the
                                most suitable players.
                              </Typography>
                            )
                          }
                        />
                        {user.goals.isCollegeTeamRecruitingPlayers &&
                          user.isVerifiedCollegeRep && (
                            <>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: "500",
                                  mb: 2.5,
                                  ml: 1,
                                  mt: 2,
                                }}
                              >
                                Player College Profile
                              </Typography>
                              <Box
                                sx={{
                                  display: "grid",
                                  rowGap: 3,
                                  columnGap: 2,
                                  gridTemplateColumns: {
                                    xs: "repeat(1, 1fr)",
                                    sm: "repeat(2, 1fr)",
                                  },
                                  mb: 3,
                                }}
                              >
                                <Stack px={1}>
                                  <Typography>Graduation Year</Typography>
                                  <Controller
                                    name="yearRange"
                                    control={control}
                                    render={({ field }) => (
                                      <Slider
                                        {...field}
                                        min={2018}
                                        max={2030}
                                        step={1}
                                        valueLabelDisplay="on" // Always show the value labels
                                        valueLabelFormat={(value) => `${value}`}
                                        marks
                                        value={field.value} // Controlled by React Hook Form
                                        onChange={(_, newValue) =>
                                          field.onChange(newValue)
                                        } // Update state on change
                                        sx={{
                                          "& .MuiSlider-thumb": {
                                            "&:hover, &.Mui-focusVisible, &.Mui-active":
                                            {
                                              boxShadow:
                                                "0px 0px 0px 8px rgba(90, 175, 226, 0.16)",
                                            },
                                          },
                                          "& .MuiSlider-valueLabel": {
                                            top: 45, // Position the label below the slider
                                            // transform: "translateY(100%)", // Adjust label position
                                            "&:before": {
                                              display: "none", // Hide the arrow of the label
                                            },
                                          },
                                          maxWidth: "95%",
                                          ml: 1,
                                          mb: 2,
                                        }}
                                      />
                                    )}
                                  />
                                </Stack>
                                <Stack px={1}>
                                  <Typography>GPA</Typography>
                                  <Controller
                                    name="gpaRange"
                                    control={control}
                                    render={({ field }) => (
                                      <Slider
                                        {...field}
                                        min={0}
                                        max={6.0}
                                        step={0.5}
                                        valueLabelDisplay="on" // Always show the value labels
                                        valueLabelFormat={(value) => `${value}`}
                                        marks
                                        value={field.value} // Controlled by React Hook Form
                                        onChange={(_, newValue) =>
                                          field.onChange(newValue)
                                        } // Update state on change
                                        sx={{
                                          "& .MuiSlider-thumb": {
                                            "&:hover, &.Mui-focusVisible, &.Mui-active":
                                            {
                                              boxShadow:
                                                "0px 0px 0px 8px rgba(90, 175, 226, 0.16)",
                                            },
                                          },
                                          "& .MuiSlider-valueLabel": {
                                            top: 45, // Position the label below the slider
                                            // transform: "translateY(100%)", // Adjust label position
                                            "&:before": {
                                              display: "none", // Hide the arrow of the label
                                            },
                                          },
                                          maxWidth: "95%",
                                          ml: 1,
                                          mb: 2,
                                        }}
                                      />
                                    )}
                                  />
                                </Stack>
                                <Controller
                                  name={`collegeType`}
                                  defaultValue={[]}
                                  control={control}
                                  render={({
                                    field: desiredCollegeType,
                                    fieldState: { error },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      limitTags={2}
                                      id="multiple-limit-tags-college-type"
                                      options={collegeType.map(
                                        ({ type }) => type
                                      )}
                                      {...desiredCollegeType}
                                      getOptionLabel={(option) => option}
                                      defaultValue={[]}
                                      onChange={(_, data) =>
                                        desiredCollegeType.onChange(data)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="College Type"
                                          error={!!error}
                                          helperText={error?.message || ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`desiredDegrees`}
                                  defaultValue={[]}
                                  control={control}
                                  render={({
                                    field: desiredDegreesField,
                                    fieldState: { error },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      limitTags={2}
                                      id="multiple-limit-tags-college-degree"
                                      options={desiredDegrees.map(
                                        ({ name }) => name
                                      )}
                                      {...desiredDegreesField}
                                      getOptionLabel={(option) => option}
                                      defaultValue={[]}
                                      onChange={(_, data) =>
                                        desiredDegreesField.onChange(data)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Desired Degrees"
                                          error={!!error}
                                          helperText={error?.message || ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`desiredSchools`}
                                  defaultValue={[]}
                                  control={control}
                                  render={({
                                    field: desiredSchoolsField,
                                    fieldState: { error },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      limitTags={2}
                                      id="multiple-limit-tags-desired-schools"
                                      options={desiredSchools.map(
                                        ({ name }) => name
                                      )}
                                      {...desiredSchoolsField}
                                      getOptionLabel={(option) => option}
                                      defaultValue={[]}
                                      onChange={(_, data) =>
                                        desiredSchoolsField.onChange(data)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Desired Schools"
                                          error={!!error}
                                          helperText={error?.message || ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`desiredRegions`}
                                  defaultValue={[]}
                                  control={control}
                                  render={({
                                    field: desiredRegionField,
                                    fieldState: { error },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      limitTags={2}
                                      id="multiple-limit-tags-desired-region"
                                      options={desiredRegions.map(
                                        ({ name }) => name
                                      )}
                                      {...desiredRegionField}
                                      getOptionLabel={(option) => option}
                                      defaultValue={[]}
                                      onChange={(_, data) =>
                                        desiredRegionField.onChange(data)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Desired Region"
                                          error={!!error}
                                          helperText={error?.message || ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Box>
                            </>
                          )}
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{ maxWidth: { md: "20%" }, mt: 2 }}
                        >
                          Search Players
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleReset}
                          sx={{ maxWidth: { md: "20%" }, mt: 2, ml: 1 }}
                        >
                          Reset
                        </Button>
                      </FormProvider>
                    </Stack>
                  </Card>
                </Collapse>

                <Collapse in={openCard === "users"}>
                  <Card
                    sx={{
                      mt: 2,
                      p: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Stack direction="column" py={2} px={1} spacing={2}>
                      <Typography variant="h6">Users Filters</Typography>
                      <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(handleSubmitUsersFilters)}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 2,
                          }}
                        >
                          <RHFTextField
                            name="username"
                            label="Username"
                            inputProps={{
                              maxLength: 15,
                              onInput: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z0-9]/g,
                                  ""
                                ); // Replace non-alphanumeric characters
                              },
                            }}
                          />
                        </Box>
                        <Box>
                          <RHFCheckbox
                            sx={{}}
                            name="isLookingForCollegeTeam"
                            label={
                              <Typography variant="body1" align="left" sx={{}}>
                                Users looking to join a college team?
                              </Typography>
                            }
                          />
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{ mt: 2, maxWidth: { md: "20%" } }}
                        >
                          Search Users
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleReset}
                          sx={{ maxWidth: { md: "20%" }, mt: 2, ml: 1 }}
                        >
                          Reset
                        </Button>
                      </FormProvider>
                    </Stack>
                  </Card>
                </Collapse>

                <Collapse in={openCard === "teams"}>
                  <Card
                    sx={{
                      mt: 2,
                      p: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Stack direction="column" py={2} px={1} spacing={2}>
                      <Typography variant="h6">Teams Filters</Typography>
                      <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(handleSubmitTeamsFilters)}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 3,
                          }}
                        >
                          <RHFTextField name="teamName" label="Team Name" />

                          <Controller
                            name={`gamesPlayedTeam`}
                            control={control}
                            defaultValue={[]}
                            render={({
                              field: gamesPlayedField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-games-team"
                                options={games}
                                {...gamesPlayedField}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamesPlayedField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Games Played"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                        <LocationCard
                          city={values.locationCity}
                          state={values.locationState}
                          country={values.locationCountryCode}
                        />
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                        >
                          Text-Based Teams Search
                        </Typography>
                        <RHFTextField
                          name="textBasedSearchTeams"
                          label="Text-based search"
                          multiline
                          rows={4}
                          helperText={
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 1,
                                ml: -1,
                                //mx: 'auto',
                                display: "block",
                                textAlign: "left",
                                color: "text.secondary",
                              }}
                            >
                              Enter keywords to search across team descriptions
                              and announcements. This will help you find teams
                              whose profiles and aspirations match your
                              criteria.
                            </Typography>
                          }
                        />
                        <Box>
                          <RHFCheckbox
                            sx={{ mt: 1 }}
                            name="isCollegeTeam"
                            label={
                              <Typography
                                variant="body1"
                                align="left"
                                sx={{ mt: 1 }}
                              >
                                Search only for college teams?
                              </Typography>
                            }
                          />
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{ mt: 2, maxWidth: { md: "20%" } }}
                        >
                          Search Teams
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleReset}
                          sx={{ maxWidth: { md: "20%" }, mt: 2, ml: 1 }}
                        >
                          Reset
                        </Button>
                      </FormProvider>
                    </Stack>
                  </Card>
                </Collapse>

                <Collapse in={openCard === "organizations"}>
                  <Card
                    sx={{
                      mt: 2,
                      p: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Stack direction="column" py={2} px={1} spacing={2}>
                      <Typography variant="h6">
                        Organizations Filters
                      </Typography>
                      <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(
                          handleSubmitOrganizationsFilters
                        )}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 2,
                          }}
                        >
                          <RHFTextField
                            name="organizationName"
                            label="Organization Name"
                          />

                          <Controller
                            name={`gamesPlayedOrg`}
                            control={control}
                            defaultValue={[]}
                            render={({
                              field: gamesPlayedFieldOrg,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-games-org"
                                options={games.map(({ name }) => name)}
                                {...gamesPlayedFieldOrg}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamesPlayedFieldOrg.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Games Played"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />

                          <Controller
                            name={`degreesOffered`}
                            control={control}
                            defaultValue={[]}
                            render={({
                              field: degreesOfferedField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags-degrees-offered"
                                options={desiredDegrees.map(({ name }) => name)}
                                {...degreesOfferedField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  degreesOfferedField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Degrees Offered"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                        <RHFCheckbox
                          sx={{ mb: 1 }}
                          name="scholarshipsOffered"
                          label={
                            <Typography
                              variant="body1"
                              align="left"
                              sx={{ mb: 1 }}
                            >
                              Scholarships offered?
                            </Typography>
                          }
                        />
                        <LocationCard
                          city={values.locationCity}
                          state={values.locationState}
                          country={values.locationCountryCode}
                        />

                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                        >
                          Text-Based Organizations Search
                        </Typography>
                        <RHFTextField
                          name="textBasedSearchOrganizations"
                          label="Text-based search"
                          multiline
                          rows={4}
                          helperText={
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 1,
                                ml: -1,
                                display: "block",
                                textAlign: "left",
                                color: "text.secondary",
                              }}
                            >
                              Enter keywords to search across organization
                              descriptions, academic mission, admission
                              requirements, tuition amounts, scholarship
                              information, and announcements. This will help you
                              find organizations whose profiles match your
                              criteria.
                            </Typography>
                          }
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{ mt: 2, maxWidth: { md: "20%" } }}
                        >
                          Search Organizations
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleReset}
                          sx={{ maxWidth: { md: "20%" }, mt: 2, ml: 1 }}
                        >
                          Reset
                        </Button>
                      </FormProvider>
                    </Stack>
                  </Card>
                </Collapse>

                <Collapse in={openCard === "stats"}>
                  <Card
                    sx={{
                      mt: 2,
                      p: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Stack direction="column" py={2} px={1} spacing={2}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                      >
                        Stats Filters
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 1,
                          mb: 1,
                          ml: -1,
                          display: "block",
                          textAlign: "left",
                          color: "text.secondary",
                        }}
                      >
                        Indicate a minimum value for each statistic.
                      </Typography>
                      <FormProvider
                        methods={methods} // You can reuse the same `methods` if both forms use the same validation schema
                        onSubmit={handleSubmit(handleSubmitStatsFilters)}
                      >
                        {/* Game Filters: Search by KDA, CS, League Point, K, D, A */}
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 3,
                          }}
                        >
                          {selectedGame &&
                            selectedGame.includes("League of Legends") && (
                              <>
                                <RHFTextField
                                  name="leaguePoints"
                                  label="League Points"
                                  type="number"
                                />
                                <RHFTextField
                                  type="number"
                                  name="cs"
                                  label="Average CS"
                                />
                              </>
                            )}
                          <RHFTextField
                            type="number"
                            name="kda"
                            label="Average KDA"
                          />
                          <RHFTextField
                            type="number"
                            name="kills"
                            label="Average Kills"
                          />
                          <RHFTextField
                            type="number"
                            name="deaths"
                            label="Average Deaths"
                          />
                          <RHFTextField
                            type="number"
                            name="assists"
                            label="Average Assists"
                          />
                        </Box>

                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", mb: 2.5, ml: 1, mt: 2 }}
                        >
                          Player Filters
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                            mb: 3,
                          }}
                        >
                          <RHFTextField name="gamerID" label="Gamer ID" />
                          <Controller
                            name={`type`}
                            defaultValue={[]}
                            control={control}
                            render={({
                              field: gamerTypeField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="player-type-tags"
                                options={playerTypes}
                                {...gamerTypeField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamerTypeField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Player Type"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                          <Controller
                            name={`game`}
                            defaultValue={""}
                            control={control}
                            render={({
                              field: gameField,
                              fieldState: { error },
                            }) => (
                              <RHFSelect
                                name="game"
                                label="Game"
                                onChange={(e) => {
                                  gameField.onChange(e.target.value);
                                  setSelectedGame(e.target.value);
                                }}
                                value={selectedGame}
                              >
                                <optgroup>
                                  <option
                                    key={"None"}
                                    selected
                                    value={""}
                                  ></option>
                                  {[
                                    {
                                      name: "League of Legends",
                                      _id: "League of Legends",
                                    },
                                  ].map((game) => (
                                    <option
                                      key={game.name}
                                      id={game._id}
                                      value={game.name}
                                    >
                                      {game.name}
                                    </option>
                                  ))}
                                </optgroup>
                              </RHFSelect>
                            )}
                          />
                          <Controller
                            defaultValue={[]}
                            sx={{ mt: 2, maxWidth: { md: "50%" } }}
                            name={`gamerTags`}
                            control={control}
                            render={({
                              field: gamerTagsField,
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={gamerTags.map(({ name }) => name)}
                                {...gamerTagsField}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                onChange={(_, data) =>
                                  gamerTagsField.onChange(data)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Gamer Tags"
                                    error={!!error}
                                    helperText={error?.message || ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{ maxWidth: { md: "20%" }, mt: 2 }}
                        >
                          Search Stats
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleReset}
                          sx={{ maxWidth: { md: "20%" }, mt: 2, ml: 1 }}
                        >
                          Reset
                        </Button>
                      </FormProvider>
                    </Stack>
                  </Card>
                </Collapse>
              </Scrollbar>
            </Card>
          )}
        </Loader>

        {searchInitiated && (
          <Card>
            <Divider />

            <Scrollbar>
              <TableContainer sx={{ minWidth: '1vw', position: "relative" }}>
                {isSearching ? ( // Show CircularProgress when loading
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: 400 }}
                  >
                    <CircularProgress sx={{ mb: 5, mt: 5 }} />
                  </Stack>
                ) : (
                  <Table size={dense ? "small" : "medium"}>
                    <TableHeadCustom
                      headLabel={
                        filteredResult.some(
                          (result) => result.leaguePoints !== undefined
                        )
                          ? TABLE_HEAD.slice(0, 2).concat(
                            ...LOL_HEAD,
                            TABLE_HEAD.slice(2)
                          )
                          : TABLE_HEAD
                      }
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row._id)
                        )
                      }
                    />

                    <TableBody>
                      {filteredResult.map(
                        ({
                          avatar,
                          type,
                          name,
                          leaguePoints,
                          gamerId,
                          averages,
                          _id,
                        }) => (
                          <TableRow
                            sx={{ cursor: "pointer" }}
                            hover
                            key={_id}
                            onClick={() => {
                              window.location.href = `/dashboard/public/${type.toLowerCase()}/${_id}`;
                            }}
                          >
                            <TableCell padding="checkbox" align="left">
                              <Avatar name={name} src={avatar} />
                            </TableCell>
                            <TableCell align="left">{name}</TableCell>
                            {leaguePoints !== undefined &&
                              averages !== undefined && (
                                <>
                                  <TableCell align="left">{gamerId}</TableCell>
                                  <TableCell align="center">
                                    {leaguePoints}
                                  </TableCell>
                                  <TableCell align="center">
                                    {averages.kda}
                                  </TableCell>
                                </>
                              )}
                            <TableCell align="center">
                              {type && (
                                <Chip label={type} variant="filled"></Chip>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}

                      <TableNoData isNotFound={!isLoading && !results.length} />
                    </TableBody>
                  </Table>
                )}
              </TableContainer>

              {isLoading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress sx={{ mb: 5, mt: 5 }} />
                </Stack>
              )}
            </Scrollbar>

            <Box sx={{ position: "relative" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={results.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        )}
      </Container>
    </Page>
  );
}
