import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import MyAvatar from "../../../../components/MyAvatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme, cover }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${cover})`,
  backgroundSize: "cover", // Ensure the background image covers the entire area
  backgroundPosition: "center", // Center the background image
  "&:before": {
    backgroundImage: `url(${cover})`,
    backgroundSize: "cover", // Ensure the background image covers the entire area
    backgroundPosition: "center",
    top: 0,
    zIndex: 9,
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

ProfileCover.propTypes = {
  myProfile: PropTypes.object,
};

export default function ProfileCover({
  profile: { firstName = "", lastName = "", username = "", cover = "" } = {},
}) {
  return (
    <RootStyle cover={"/assets/ecruit/profile.webp"}>
      <InfoStyle>
        <MyAvatar
          sx={{
            mx: "auto",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          profile="playerProfileImage"
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {(firstName || lastName) && (
            <Typography variant="h4">
              {firstName} {lastName}{" "}
            </Typography>
          )}
          {username && (
            <Typography sx={{ opacity: 0.72 }}>{username}</Typography>
          )}
        </Box>
      </InfoStyle>
    </RootStyle>
  );
}
